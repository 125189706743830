import {api} from '../../../shared/services/backend-api'

const RESOURCE = 'standing_orders'



const requestStandingOrder = async (unitID, email, amount, volunteerCode = null) => {

  const payload = {
    email: email,
    amount: amount,
    volunteerCode: volunteerCode
  }

  const { data } = await api.post(`${RESOURCE}/newForUnit/${unitID}`, payload)
  const {
    hash
  } = data

  return {
    hash
  }
}

export {
  requestStandingOrder
}
