<template>
  <div class="app-container"
       v-loading.fullscreen="isLoading"
       element-loading-background="rgba(255, 255, 255, 1)"
  >
    <el-row class="row-card">
      <el-col :lg="{span: 18, offset: 3}">
        <el-card>
          <h2 class="heading page-heading">
            {{ $t(`page_purpose_payment.title.${unit.unitType}`) }}
          </h2>
          <div class="unit-info">
            <div class="d-flex align-center unit-info__item">
              <v-icon color="#606266" name="home"/>
              <span>
                {{ $t('page_purpose_payment.card_info.unit_title') }}
              </span>
              <span>
                &nbsp;-&nbsp;
              </span>
              <span class="font-semi-bold">
                {{ unit.unitTitle }}
              </span>
            </div>
            <div class="d-flex align-center unit-info__item">
              <v-icon color="#606266" name="flag"/>
              <span>
                {{ $t('page_purpose_payment.card_info.unit_purpose_title') }}
              </span>
              <span>
                &nbsp;-&nbsp;
              </span>
              <span class="font-semi-bold">
                {{ unit.purposeTitle }}
              </span>
            </div>
            <UnitProgressRenderless
                :progress="unit.collectedMoney"
                :total="unit.moneyPurpose"
            >
              <template #default="{progressDifference}">
                <div class="d-flex align-center unit-info__item">
                  <v-icon color="#606266" name="attach_money"/>
                  <span>
                    {{ $t('page_purpose_payment.card_info.unit_money_difference') }}
                  </span>
                  <span>
                    &nbsp;-&nbsp;
                  </span>
                  <span class="font-semi-bold">
                    {{ $options.moneyFormat(progressDifference) }}
                  </span>
                </div>
              </template>
            </UnitProgressRenderless>
            <el-divider></el-divider>
            <div class="d-flex align-center unit-info__item" v-if="volunteerCode">
              <v-icon color="#606266" class="el-icon-s-custom"  />
              <span>
                {{ $t('standing_order_payment.card_info.unit_volunteer_id') }}
              </span>
              <span>
                &nbsp;-&nbsp;
              </span>
              <span class="font-semi-bold">
                {{ volunteerCode }}
              </span>
            </div>
            <div class="d-flex align-center unit-info__item">
              <v-icon color="#606266" name="email"/>
              <span>
                {{ $t('standing_order_payment.card_info.provided_email') }}
              </span>
              <span>
                &nbsp;-&nbsp;
              </span>
              <span class="font-semi-bold">
                {{ donorEmail }}
              </span>
            </div>

          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row class="row-card">
      <el-col :lg="{span: 18, offset: 3}">
        <el-card>
            <h2 class="heading">
              {{ $t('standing_order_payment.payment_data.title') }}
            </h2>
            <el-divider></el-divider>
            <div class="label">
              <span>{{ $t('standing_order_payment.payment_data.receiver') }}</span>
            </div>
            <div>
              <span class="font-semi-bold">{{ paymentDetails.receiver }}</span>

              <span class="item-copy font-semi-bold" @click="copyItemData(paymentDetails.receiver)">
                <v-icon color="#409EFF" name="copy"/> {{ $t('standing_order_payment.payment_data.action.copy_receiver') }}
              </span>
            </div>
            <el-divider></el-divider>
            <div class="label">
              <span>{{ $t('standing_order_payment.payment_data.account_no') }}</span>
            </div>
            <div>
              {{ paymentDetails.accountBank }}: <span class="font-semi-bold">{{ paymentDetails.accountNo }}</span>

              <span class="item-copy font-semi-bold" @click="copyItemData(paymentDetails.accountNo)">
                <v-icon color="#409EFF" name="copy"/> {{ $t('standing_order_payment.payment_data.action.copy_account_no') }}
              </span>

            </div>

            <el-divider></el-divider>
            <div class="label">
              <span>{{ $t('standing_order_payment.payment_data.amount') }}</span>
            </div>
            <div>
              <span class="font-semi-bold">{{ paymentDetails.amount }}</span>

              <span class="item-copy font-semi-bold" @click="copyItemData(paymentDetails.amount)">
                <v-icon color="#409EFF" name="copy"/> {{ $t('standing_order_payment.payment_data.action.copy_amount') }}
              </span>

            </div>
            <el-divider></el-divider>
            <div class="label">
              <span>{{ $t('standing_order_payment.payment_data.payment_title') }}</span>
            </div>
            <div>
              <span class="font-semi-bold">{{ paymentDetails.paymentTitle }}</span>

              <span class="item-copy font-semi-bold" @click="copyItemData(paymentDetails.paymentTitle)">
                <v-icon color="#409EFF" name="copy"/> {{ $t('standing_order_payment.payment_data.action.copy_payment_title') }}
              </span>


            </div>
            <el-divider></el-divider>
            <el-row>
              <el-col>
                <div class="payment-card-actions">
                  <div>
                    <el-button type="primary" @click="getPdf">
                      <v-icon color="#fff" name="download"/>
                      {{ $t('standing_order_payment.btn_get_pdf') }}
                    </el-button>
                    <el-button @click="goToUnitPage">
                      {{ $t('page_purpose_payment.btn_cancel') }}
                    </el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
        </el-card>
      </el-col>
    </el-row>
    <BankPaymentCard />
  </div>
</template>

<script>
import {numberValueValidator} from '../../shared/validators/numberValueValidator'
import {aboveZeroNumberValidator} from '../../shared/validators/aboveZeroNumberValidator'
import {requestPurposePayment} from '../shared/services/payment-service'
import {fetchUnitPaymentInfo} from '../../client/unit-page/services/unit-sevice'
import {moneyFormat} from '../../shared/utils/moneyFormat'
import VIcon from '../../components/ui/v-icon/VIcon'
import UnitProgressRenderless from '../../client/components/units/unit-progress/UnitProgressRenderless'
import {mapGetters, mapState} from 'vuex'
import BankPaymentCard from '../shared/components/BankPaymentCard'
import {UNIT_PURPOSES_ROUTE_NAME, PURPOSE_PAYMENT_ROUTE_NAME} from '../../shared/constants/app-routes-names'
import {TERM_OF_REGULATION_DOCUMENT} from '../../shared/constants/app-documents'

import {copyToClipboard} from '../../shared/utils/copyToClipboard'
import {requestStandingOrder} from '../shared/services/standing-order-service'


const MAX_MONEY_AMOUNT = 100_000

export default {
  name: 'StandingOrderPayment',
  components: {
    BankPaymentCard,
    UnitProgressRenderless,
    VIcon,
  },
  TERM_OF_REGULATION_DOCUMENT,
  moneyFormat,
  data() {
    return {
      isLoading: true,
      isVolunteerCode: false,
      paymentDetails: {
        receiver: "Pallotyńska Fundacja Misyjna Salvatti.pl",
        accountNo: "56 1140 1010 0000 4414 9200 1001",
        accountBank: "mBank",
        amount: 0,
        paymentTitle: "Wygenerowany numer zbiórki"
      },
      unit: {
        unitTitle: '',
        unitType: '',
        collectedMoney: 0,
        moneyPurpose: 0,
        provided_email: ""
      },
      donorEmail: "",
      volunteerCode: null,

    }
  },
  created() {
    this.donorEmail = this.$route.params.email
    if(this.$route.params.volunteerCode) {
      this.volunteerCode = this.$route.params.volunteerCode
    }
    if(this.$route.params.amount) {
      this.paymentDetails.amount = this.$route.params.amount
    }

    this.fetchUnitPreview()

  },
  inject: ['responsive'],
  computed: {
    ...mapGetters({
      isLoggedIn: 'authState/isLoggedIn',
      getUserEmail: 'authState/getUserEmail',
    }),
    ...mapState('unitState', {
      refererCode: state => state.refererCode
    }),
    isMd() {
      return this.responsive.isMd
    }
  },
  methods: {
    maxRangeValidator(_, value, callback) {
      if (value > MAX_MONEY_AMOUNT) {
        callback(new Error(`${this.$t('page_purpose_payment.validators.max_money_value', {maxValue: MAX_MONEY_AMOUNT})}`))
      }
      callback()
    },
    getPdf() {
      console.log("Get pdf")
    },
    async fetchUnitPreview() {
      if(this.paymentDetails.amount) {

        this.isLoading = true
        const {unitID} = this.$route.params

        const {
          unitType,
          unitTitle,
          moneyPurpose,
          collectedMoney,
          purposeTitle,
          volunteerCode,
          providedEmail
        } = await fetchUnitPaymentInfo(unitID)
        this.unit = {
          unitType,
          unitTitle,
          moneyPurpose,
          collectedMoney,
          purposeTitle,
          volunteerCode,
          providedEmail
        }

          const{
            hash
          } = await requestStandingOrder(unitID, this.donorEmail, this.paymentDetails.amount, this.volunteerCode)


        this.paymentDetails.paymentTitle = hash;

        this.isLoading = false
      } else {
        this.goToDonatePage()
      }

    },
    goToUnitPage() {
      const {unitID} = this.$route.params
      this.$router.push({
        name: UNIT_PURPOSES_ROUTE_NAME,
        params: {
          unitID
        }
      })
    },
    goToDonatePage() {
      const {unitID} = this.$route.params
      this.$router.push({
        name: PURPOSE_PAYMENT_ROUTE_NAME,
        params: {
          unitID
        }
      })
    },

    copyItemData(item) {
      copyToClipboard(item).then((res) => {
        this.$message({
          showClose: false,
          message: `${this.$t(res)}`,
          type: 'success'
        })
      })
      .catch(([err, reason]) => {
        this.$message({
          showClose: false,
          message: `${this.$t(err)}: ${reason}`,
          type: 'error'
        })
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.spacer {
  margin-left: auto;
}
.label {
  margin-bottom: 5px;
  span {
    color: #909399;
    font-size: 13px;
  }
}
.item-copy {
  color: #409EFF;
  float: right;
  cursor: copy;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
</style>

